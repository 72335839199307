<template>
  <section id="exportations">
    <b-row class="mb-2">
      <b-col>
        <h1>{{ $t('fastChannels') }}</h1>
        <h5 class="text-primary">
          {{ $t('loteBatch') }}
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <b-button
          style="height: fit-content;"
          variant="danger"
          @click="goBack()"
        >
          {{
            $t("dataGeneric.goBack")
          }}
        </b-button>
      </b-col>
    </b-row>
    <b-overlay
      :show="show"
      variant="transparent"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <b-table
        :items="exportationsInfo"
        :fields="[
          isSuperuser ? { key: 'id', label: 'Task ID' } : '',
          { key: 'content', label: $t('Contents') },
          { key: 'stateTask', label: $t('stateTask') },
          { key: 'size', label: $t('size') },
          { key: 'processingTime', label: $t('processingTime') },
          { key: 'createDate', label: $t('subscriptions.createdAt') },
        ]"
        :select-mode="'single'"
        responsive="sm"
        striped
        show-empty
        :empty-text="$t('emptyText')"
        :no-border-collapse="true"
      >
        <template #cell(content)="data">
          <b-link
            v-if="!isEmpty(data.item.content)"
            :href="'/contents/contents/edit/' + data.item.content.id"
            target="_blank"
          >
            {{ data.item.content.name }}
          </b-link>
        </template>
        <template #cell(processingTime)="data">
          <div class="text-nowrap">
            <span v-if="data.item.processingTime">
              {{ data.item.processingTime +' '+$t('Segundos') }}
            </span>
          </div>
        </template>
        <template #cell(size)="data">
          <div class="text-nowrap">
            {{ formatSize(data.item.size) }}
          </div>
        </template>
        <template #cell(createDate)="data">
          <div class="text-nowrap">
            {{ formateDateTime(data.item.createDate) }}
          </div>
        </template>
      </b-table>
      <div
        v-if="hasMore"
        class="d-flex justify-content-center align-self-center mt-2"
      >
        <small>{{ $t('downToSeeEl') }}</small><feather-icon
          icon="ArrowDownIcon"
          size="20"
          class="ml-50"
        />
      </div>
    </b-overlay>
    <div ref="infiniteScroll" />
  </section>
</template>
<script>

import {
  BRow,
  BCol,
  BLink,
  BTable,
  BOverlay,
  VBTooltip,
  BButton,
} from 'bootstrap-vue'
import {
  messageError,
  isEmpty,
  formateDateTime,
} from '@/store/functions'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BTable,
    BButton,
    BLink,
    BRow,
    BCol,
    BOverlay,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      formateDateTime,
      isEmpty,
      show: false,
      headers: {},
      exportationsInfo: [],
      isSuperuser: false,
      lastKey: '',
      fastChannels: [],
      isLoading: false,
      hasMore: true,
      batchId: this.$route.params.id != null ? this.$route.params.id : null,
    }
  },

  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = getUserData().profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) { }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    this.isSuperuser = getUserData().isSuperuser

    if (this.batchId) this.handleScroll()
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      const element = this.$refs.infiniteScroll
      const rect = element.getBoundingClientRect()
      if (rect.top <= window.innerHeight && this.hasMore && !this.isLoading) {
        this.channelExportations(this.batchId)
      }
    },

    goBack() {
      window.history.back()
    },
    formatSize(value) {
      if (value == null) return `${0} Bytes`
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (value === 0) return '0 Byte'
      const i = parseInt(Math.floor(Math.log(value) / Math.log(1024)), 10)
      return `${Math.round(value / 1024 ** i, 2)} ${sizes[i]}`
    },
    channelExportations(id) {
      this.show = true
      const query = `{
          allFastChannelExportations(batchId: "${id}",exclusiveStartKey:"${this.lastKey}") {
                items {
                  id
                  size
                  fileType
                  language
                  stateTask
                  processingTime
                  createDate
                  content {
                    id
                    name
                  }
                }
                lastEvaluatedKey
                limit
              }
            }
        `
      axios
        .post('', { query })
        .then(res => {
          messageError(res, this)
          const srch = res.data.data.allFastChannelExportations.items
          if (!isEmpty(srch)) {
            this.exportationsInfo = [...this.exportationsInfo, ...srch]
            this.lastKey = res.data.data.allFastChannelExportations.lastEvaluatedKey
            this.testMore(id)
          } else {
            this.exportationsInfo = [...this.exportationsInfo, ...srch]
            this.hasMore = false
          }
          this.isLoading = false
          this.show = false
        })
        .catch(() => {
        })
    },
    testMore(id) {
      const query = `{
          allFastChannelExportations(batchId: "${id}",exclusiveStartKey:"${this.lastKey}") {
                items {
                  id
                  size
                  fileType
                  language
                  stateTask
                  createDate
                  content {
                    id
                    name
                  }
                }
                lastEvaluatedKey
                limit
              }
            }
        `
      axios
        .post('', { query })
        .then(res => {
          messageError(res, this)
          const srch = res.data.data.allFastChannelExportations.items
          if (isEmpty(srch)) {
            this.hasMore = false
          }
        })
        .catch(() => {
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

.card-body h4 {
  font-size: 1.286rem !important;
}

.col-form-label {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.fly-image-input {
  max-width: 100%;
}

.base-color-input {
  display: block;
  border-radius: 15px;
  border: solid 1px;
  border-color: white;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.base-card-input {
  display: block;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.div {
  display: inline-block;
}

.inputColor {
  visibility: hidden;
}

.placeholder_photo {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

.placeholder_photo:hover {
  background: #e0e0e0;
}

.file-input {
  display: none;
}

.cBox {
  align-items: center;
}

.custom-control-label {
  font-size: 15px;
}

.scroll {
  overflow: hidden;
  flex-wrap: nowrap !important;

}

.list {
  overflow: hidden;
  overflow-y: scroll;
  height: 380px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: none;
}

.contain {
  object-fit: contain;
  cursor: pointer;
}

.image_avatar {
  max-width: initial;
  max-height: max-content;
  height: 100%;
  width: 100%;
}

.editImage {
  position: absolute;
}

.profile-pic:hover .editImage {
  display: block;
}

.profile-pic {
  position: relative;
  display: inline-block;
}

#exportations .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#exportations .card-body {
  padding: 0rem;
}

#exportations .card-body h4 {
  font-size: 1rem !important;
}

#exportations .card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

#exportations #button-content {
  padding: 0px;
}

#exportations #acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

#exportations #acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}

#exportations .acortarParrafo {
  display: block;
  display: -webkit-box;
  line-height: 1.4;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
}

#exportations .wrap {
  white-space: pre-wrap;
}

#exportations .body-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}

#exportations .noBorder {
  border-bottom: 0px;
  width: -webkit-fill-available;
  width: -moz-available;
}

#exportations .text-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  text-decoration: underline;
  width: 100px;
  /* Ajusta el ancho según tus necesidades */
  display: block;
  /* Asegura que se comporte como un bloque para que text-overflow funcione */
}
</style>
